<template>
  <div class="mb-5">
    <h4 class="mt-3">Брак на суровина</h4>
    <v-row class="t-head">
      <v-col>Дата</v-col>
      <v-col>Бройка</v-col>
      <v-col>Потребител</v-col>
      <v-col>Изтриване</v-col>
    </v-row>
    <v-row
      v-for="scrap in routemap.rawProductScraps"
      :key="scrap.id"
      class="row border-bottom"
    >
      <v-col class="t-row">{{ scrap.created_at }}</v-col>
      <v-col class="t-row">{{ scrap.quantity }}</v-col>
      <v-col class="t-row">{{ scrap.userName }}</v-col>
      <v-col class="t-row">
        <span
          v-if="['Admin', 'Qualified', 'Manager'].includes(user.role)"
          class="btn btn-block btn-danger"
          @click="handleDeleteScrapClicked(scrap.id)"
          >Изтриване</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
    },
    user: {
      required: true,
      type: Object,
    },
  },

  methods: {
    handleDeleteScrapClicked(scrapId) {
      if (
        this.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(this.user.role)
      ) {
        return;
      }

      this.$emit("delete-raw-product-scrap", scrapId);
    },
  },
};
</script>

<style scoped></style>
