<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ routemap.name }} - {{ routemap.quantity }} бр. /
        {{ routemap.hallNames }}
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        <v-row>
          <h4>
            Оператор:
            <strong>
              {{ editedOperation.operator }}
            </strong>
          </h4>

          <v-col class="col-12">
            Операция
            <select v-model="form.operationId" type="text" class="form-control">
              <option disabled v-bind:value="0">избери</option>
              <option
                v-for="operation in routemap.productOperations"
                v-bind:key="operation.id"
                v-bind:value="operation.id"
              >
                {{ operation.name }}</option
              >
            </select>
          </v-col>
          <v-col class="col-12">
            Бройка
            <input v-model="form.quantity" type="text" class="form-control" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleSaveClicked()">
          Промени
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    editedOperation: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        id: this.editedOperation.id,
        routeMapId: this.routemap.id,
        operatorId: this.editedOperation.operatorId,
        operationId: this.editedOperation.operationId,
        quantity: this.editedOperation.quantity
      },
      localErrorMessages: {}
    };
  },

  watch: {
    value() {
      this.clearForm();
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  methods: {
    handleSaveClicked() {
      if (this.form.operationId < 1) {
        this.localErrorMessages = {
          0: ["Моля изберете операция"]
        };

        return;
      }

      if (this.form.quantity < 1) {
        this.localErrorMessages = {
          0: ["Броя на извършените операции трябва да бъде минимум 1"]
        };

        return;
      }

      this.$emit("save-clicked", this.form);
    },

    handleDialogInput(value) {
      this.clearForm();

      this.$emit("input", value);
    },

    clearForm() {
      this.form = {
        id: this.editedOperation.id,
        routeMapId: this.routemap.id,
        operatorId: this.editedOperation.operatorId,
        operationId: this.editedOperation.operationId,
        quantity: this.editedOperation.quantity
      };

      this.localErrorMessages = {};
    }
  }
};
</script>
