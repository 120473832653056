<template>
  <div class="alert alert-warning">
    <div class="row">
      <div class="col-12 pl-0">
        <h4>
          {{ routemap.nextPause.minutes }} минути почивка от
          {{ routemap.nextPause.from }} до {{ routemap.nextPause.to }} <br />
        </h4>
      </div>
      <div class="d-flex">
        <h4>Оставащо време:</h4>
        <h4 class="ml-3">
          <countdown
            :time="routemap.nextPause.secondsToFinishPause * 1000"
            @end="$emit('break-finished')"
            :transform="
              props => {
                Object.entries(props).forEach(([key, value]) => {
                  // Adds leading zero
                  const digits = value < 10 ? `0${value}` : value;
                  props[key] = `${digits}`;
                });
                return props;
              }
            "
          >
            <template slot-scope="props">
              {{ props.minutes }} мин. {{ props.seconds }} с.
            </template>
          </countdown>
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  }
};
</script>
