<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ routemap.name }}
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        Материал
        <select
          v-model="form.routeMapCostStandardId"
          type="text"
          class="form-control"
        >
          <option disabled v-bind:value="0">избери</option>
          <template v-for="cost in routemap.costStandards">
            <option
              v-if="cost.type != 'Лекарствен Продукт'"
              v-bind:key="cost.id"
              v-bind:value="cost.id"
            >
              {{ cost.type }} {{ cost.name }} ({{ cost.batch }})
            </option>
          </template>
        </select>

        <template>
          Брой на бракувани материали
          <input
            type="number"
            min="1"
            v-model="form.quantity"
            class="form-control"
          />
        </template>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click="handleSaveClicked">
          <span class="btn btn-primary btn-block">
            Запиши
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        routeMapCostStandardId: 0,
        quantity: 1
      },
      localErrorMessages: {}
    };
  },

  watch: {
    value() {
      this.clearForm();
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  methods: {
    handleSaveClicked() {
      if (this.form.routeMapCostStandardId < 1) {
        this.localErrorMessages = {
          0: ["Моля изберете материал"]
        };

        return;
      }

      if (this.form.quantity < 1) {
        this.localErrorMessages = {
          0: ["Броя на бракувани материали трябва да бъде минимум 1"]
        };

        return;
      }

      this.$emit("save-clicked", this.form);
    },

    handleDialogInput(value) {
      this.clearForm();

      this.$emit("input", value);
    },

    clearForm() {
      this.form = {
        routeMapCostStandardId: 0,
        quantity: 1
      };

      this.localErrorMessages = {};
    }
  }
};
</script>
