<template>
  <div>
    <h4 class="mt-3">
      Статистика
    </h4>
    <div class="row">
      <div class="col-4">
        <div class="row no-gutters">
          <div class="col-6 mb-2 text-strong">Бройки за производство</div>
          <div class="col-6 mb-2">{{ routemap.quantity }}</div>

          <div class="col-6 mb-2 text-strong">Готови бройки</div>
          <div class="col-6 mb-2">{{ routemap.readyQuantity }}</div>

          <div class="col-6 mb-2 text-strong">Оставащи бройки</div>
          <div class="col-6 mb-2">
            {{ routemap.remainQuantity > 0 ? routemap.remainQuantity : 0 }}
          </div>

          <div class="col-6 mb-2 text-strong">Брак на суровина</div>
          <div class="col-6 mb-2">{{ routemap.scrapQuantity }}</div>
        </div>
      </div>

      <div class="col-8">
        <GChart type="PieChart" :data="chartData" :options="chartOptions" />
      </div>
    </div>

    <hr />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  components: { GChart },
  props: {
    routemap: {
      required: true,
      type: Object
    },
    chartData: {
      required: true,
      type: Array
    },
    chartOptions: {
      required: true,
      type: Object
    }
  }
};
</script>
