<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ routemap.name }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="canBeFinished">
            Сигурни ли сте, че искате да приключите маршрутната карта?
          </v-col>
          <ErrorMessages
            v-if="!canBeFinished"
            :error-messages="{
              remainQuantity: [
                `Не са изпълнени всички бройки. Остават ${routemap.remainQuantity} бройки.`
              ]
            }"
          />
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions v-if="canBeFinished">
        <v-spacer></v-spacer>
        <v-btn text @click="handleFinishClicked">
          <span class="btn btn-primary btn-block">
            Потвърди
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/views/pages/routemaps/components/actions/messages/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },
  computed: {
    canBeFinished() {
      return this.routemap.remainQuantity === 0;
    }
  },
  props: {
    routemap: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      confirmation: false
    };
  },

  watch: {
    value() {
      this.confirmation = false;
    }
  },

  methods: {
    handleFinishClicked() {
      if (this.routemap.remainQuantity > 0 && !this.confirmation) {
        this.$emit("input", false);
        return;
      }

      this.$emit("finish-clicked");
    },

    handleDialogInput(value) {
      this.confirmation = false;

      this.$emit("input", value);
    }
  }
};
</script>
