<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Извърши контрол на качеството
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        <v-row>
          <v-col cols="12">
            <input
              v-model="form.package"
              type="checkbox"
              class="form-control dl-inline"
            />
            Външен вид, цвят<br />
            цялост на опаковката
          </v-col>
          <v-col cols="12">
            <input
              v-model="form.instructions"
              type="checkbox"
              class="form-control dl-inline"
            />
            Съответствие на продукта с инструкциите за преопаковане
          </v-col>
          <v-col cols="12">
            <input
              v-model="form.batch"
              type="checkbox"
              class="form-control dl-inline"
            />
            Четливост и достоверност на партиден &numero; и срок на годност
          </v-col>
          <v-col
            v-if="!form.batch || !form.instructions || !form.package"
            cols="12"
          >
            Забележки:
            <textarea v-model="form.notes" class="form-control"></textarea>
            <br />
            <input
              v-model="form.is_sent_to_labourer"
              type="checkbox"
              v-bind:value="1"
              class="form-control dl-inline"
            />
            Изпрати на Общ работник
          </v-col>
          <v-col cols="12">
            Код за контрол:
            <input
              type="text"
              v-model="form.controlCode"
              class="form-control"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click="handleControlClicked">
          <span class="btn btn-primary btn-block">
            Извърши контрол
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  watch: {
    value() {
      this.clearForm();
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  data() {
    return {
      form: {
        routeMapId: this.routemap.id,
        package: false,
        instructions: false,
        batch: false,
        notes: "",
        is_sent_to_labourer: false,
        controlCode: ""
      },
      localErrorMessages: {}
    };
  },

  methods: {
    handleControlClicked() {
      this.$emit("control-clicked", this.form);
    },

    handleDialogInput(value) {
      this.clearForm();

      this.$emit("input", value);
    },

    clearForm() {
      this.form = {
        routeMapId: this.routemap.id,
        package: false,
        instructions: false,
        batch: false,
        notes: "",
        is_sent_to_labourer: false,
        controlCode: ""
      };

      this.localErrorMessages = {};
    }
  }
};
</script>
