<template>
  <h4 class="alert alert-danger">
    Залата не е заредена със суровини и материали. Зареждането е планувано за
    {{ routemap.materialDate | formatDateTime }} г.
  </h4>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  }
};
</script>
