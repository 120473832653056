<template>
  <div class="alert alert-danger">
    Залата е отбелязана за повторно зареждане поради следните причини:
    <template v-if="lastControl && !lastControl.package">
      <br />
      Външен вид, цвят и цялост на опаковката.
    </template>
    <template v-if="lastControl && !lastControl.instructions">
      <br />
      Съответствие на продукта с инструкциите за преопаковане.
    </template>
    <template v-if="lastControl && !lastControl.batch">
      <br />
      Четливост и достоверност на партиден &numero; и срок на годност.
    </template>
    <template v-if="lastControl && lastControl.notes">
      <br />
      {{ lastControl.notes }}
    </template>
  </div>
</template>

<script>
import { sortBy, last } from "lodash";

export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  },

  computed: {
    lastControl() {
      const controls = sortBy(this.routemap.controls, "id");

      return last(controls);
    }
  }
};
</script>
