<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card ref="dialog">
      <v-card-title class="headline grey lighten-2">
        Приключи работа по {{ routemap.name }} - {{ routemap.quantity }} бр. /
        {{ routemap.hallNames }}
      </v-card-title>

      <v-card-text>
        <h4>
          Оператор:
          <strong>
            {{ operator.firstName }}
            {{ operator.lastName }}
          </strong>
        </h4>

        <ErrorMessages :errorMessages="localErrorMessages" />

        <v-row>
          <hr />
          <div class="col-12" v-if="form.operations.length > 0">
            <hr />
            <h5>Операции</h5>

            <div v-for="(operation, index) in form.operations" :key="index">
              <v-row v-bind:key="index">
                <v-col class="col-12">
                  Операция
                  <select
                    v-model="operation.operationId"
                    type="text"
                    class="form-control"
                  >
                    <option disabled v-bind:value="0">избери</option>
                    <option
                      v-for="op in routemap.productOperations"
                      v-bind:key="op.id"
                      v-bind:value="op.id"
                    >
                      {{ op.name }} {{ op.productName }}</option
                    >
                  </select>
                </v-col>
                <v-col class="col-12">
                  Бройка
                  <input
                    v-model="operation.quantity"
                    type="text"
                    class="form-control"
                  />
                </v-col>
              </v-row>
              <hr />
            </div>
          </div>
          <span v-on:click="addOperation()" class="btn btn-block btn-primary"
            >Добави нова операция</span
          >
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleSaveClicked">
          <span class="btn btn-primary btn-block">
            Приключи
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    operator: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        routeMapId: this.routemap.id,
        operatorId: null,
        operations: [
          {
            operationId: 0,
            quantity: 1
          }
        ]
      },
      operatorId: 0,
      localErrorMessages: {}
    };
  },

  watch: {
    value() {
      this.clearForm();
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  methods: {
    addOperation() {
      this.form.operations.push({
        operationId: 0,
        quantity: 1
      });
    },

    handleSaveClicked() {
      let invalidOperation = false;
      this.form.operations.forEach(operation => {
        if (operation.operationId < 1 || operation.quantity < 1) {
          invalidOperation = true;
        }
      });

      if (invalidOperation) {
        this.localErrorMessages = {
          0: ["Моля попълнете всички полета коректно"]
        };

        this.$refs.dialog.$el.scrollIntoView({ behavior: "smooth" });

        return;
      }

      this.form.operatorId = this.operator.id;

      this.$emit("save-clicked", this.form);
    },

    handleDialogInput(value) {
      this.clearForm();

      this.$emit("input", value);
    },

    clearForm() {
      this.form = {
        routeMapId: this.routemap.id,
        operatorId: null,
        operations: [
          {
            operationId: 0,
            quantity: 1
          }
        ]
      };

      this.localErrorMessages = {};
    }
  }
};
</script>
