<template>
  <div>
    <h4 class="mt-3">
      Извършен контрол на качеството
    </h4>

    <v-row class="t-head">
      <v-col>Дата и час</v-col>
      <v-col>Мениджър</v-col>
      <v-col
        >Външен вид, цвят<br />
        цялост на опаковката</v-col
      >
      <v-col>
        Съответствие на продукта с<br />
        инструкциите за преопаковане
      </v-col>
      <v-col
        >Четливост и достоверност на<br />
        партиден &numero; и срок на годност</v-col
      >
    </v-row>
    <template v-for="control in routemap.controls">
      <v-row class="row border-bottom" v-bind:key="'c' + control.id">
        <v-col class="t-row">{{ control.date }} {{ control.time }}</v-col>
        <v-col class="t-row">{{ control.user }}</v-col>
        <v-col class="t-row">{{
          control.package == true ? "ОТГОВАРЯ" : "НЕ ОТГОВАРЯ"
        }}</v-col>
        <v-col class="t-row">{{
          control.instructions == true ? "ОТГОВАРЯ" : "НЕ ОТГОВАРЯ"
        }}</v-col>
        <v-col class="t-row"
          >{{ control.batch == true ? "ОТГОВАРЯ" : "НЕ ОТГОВАРЯ" }}
        </v-col>
      </v-row>
    </template>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  }
};
</script>

<style scoped></style>
