<template>
  <div class="mb-5">
    <h4 class="mt-3">
      Чекирани оператори
    </h4>
    <v-row class="t-head">
      <v-col>Име</v-col>
      <v-col>Действия</v-col>
    </v-row>
    <template
      v-for="operator in _.filter(routemap.operators, {
        active: true
      })"
    >
      <v-row class="row border-bottom" v-bind:key="'o' + operator.id">
        <v-col class="t-row"
          >{{ operator.firstName }} {{ operator.lastName }}</v-col
        >
        <v-col class="t-row">
          <template v-if="['Admin', 'Manager'].includes(user.role)">
            <span
              class="btn btn-block btn-danger"
              @click="$emit('checkout-operator', operator)"
            >
              Приключи работа по тази маршрутна карта
            </span>
          </template>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "CheckedOperatorsSection",
  props: {
    routemap: {
      required: true,
      type: Object
    },
    user: {
      required: true,
      type: Object
    }
  }
};
</script>
