<template>
  <div
    v-if="
      errorMessages &&
        typeof errorMessages === 'object' &&
        Object.keys(errorMessages).length > 0
    "
    class="alert alert-danger"
  >
    <ul class="mb-0">
      <template v-for="(error, k) in errorMessages">
        <span v-bind:key="k">
          <li v-for="(message, i) in error" v-bind:key="i">
            {{ message }}
          </li>
        </span>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errorMessages: {
      default: () => {}
    }
  }
};
</script>
