<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ routemap.name }} {{ routemap.quantity }} бр.
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        Коментар:
        <textarea v-model="comment" class="form-control"></textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text>
          <span class="btn btn-success btn-block" @click="handleStartClicked">
            СТАРТ
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages,
  },

  props: {
    routemap: {
      required: true,
      type: Object,
    },

    value: {
      required: true,
      type: Boolean,
    },

    errorMessages: {
      default: () => {},
    },
  },

  data() {
    return {
      comment: "",
      localErrorMessages: {},
    };
  },

  watch: {
    value() {
      this.comment = "";
      this.localErrorMessages = {};
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    },
  },

  methods: {
    handleStartClicked() {
      if (!this.comment) {
        this.localErrorMessages = {
          0: ["Полето коментар е задължително"],
        };

        return;
      }

      this.$emit("start-clicked", {
        comment: this.comment,
      });
    },

    handleDialogInput(value) {
      this.comment = "";
      this.localErrorMessages = {};

      this.$emit("input", value);
    },
  },
};
</script>
