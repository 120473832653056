var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"btn btn-primary float-right",on:{"click":function($event){return _vm.goBack()}}},[_c('i',{staticClass:"fa fa-arrow-left fa-fw"}),_vm._v(" назад ")]),(['Admin', 'Qualified', 'Manager', 'Hall'].includes(_vm.user.role))?_c('button',{staticClass:"btn btn-danger float-right mr-2 ml-2",on:{"click":function($event){return _vm.openLog(`/logs/route-map/${_vm.routemap.id}`)}}},[_c('i',{staticClass:"fas fa-exclamation fa-fw"}),_vm._v(" лог ")]):_vm._e(),(['Admin', 'Qualified', 'Manager', 'Hall'].includes(_vm.user.role))?_c('button',{staticClass:"btn btn-warning float-right mr-2 ml-2",on:{"click":function($event){return _vm.openLog(`/logs/route-map/${_vm.routemap.id}/printout`)}}},[_c('i',{staticClass:"fas fa-exclamation fa-fw"}),_vm._v(" разпечатка ")]):_vm._e(),_c('button',{staticClass:"btn btn-success float-right mr-2 ml-2",on:{"click":function($event){return _vm.$emit('refresh-click')}}},[_c('i',{staticClass:"fas fa-sync fa-fw"})]),_c('h1',[_vm._v(" "+_vm._s(_vm.routemap.name)+" - "+_vm._s(_vm.routemap.quantity)+" бр. "),_c('br'),_vm._v(" "+_vm._s(_vm.routemap.hallNames)+" "),(
        ['Admin', 'Manager'].includes(_vm.user.role) ||
          (_vm.user.role == 'Quality Manager' && _vm.routemap.status > 1)
      )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"#000000"},on:{"click":_vm.displayHallsDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fas fa-pen ")])],1)]}}],null,false,3482584910)},[_c('span',[_vm._v("Редактирай залите")])]):_vm._e()],1),_c('h1',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.routemap.statusName)+" ")]),(_vm.routemap.status == 2 && _vm.routemap.secondsToNextControl > 0)?_c('h4',{staticClass:"mt-3",staticStyle:{"color":"red"}},[_vm._v(" Контрол на качеството след "),_c('strong',[_c('countdown',{attrs:{"auto-start":!_vm.routeMapIsPaused && !_vm.routeMapIsNew && !_vm.routeMapIsInBreak,"time":_vm.routemap.secondsToNextControl * 1000,"transform":props => {
            Object.entries(props).forEach(([key, value]) => {
              // Adds leading zero
              const digits = value < 10 ? `0${value}` : value;
              props[key] = `${digits}`;
            });
            return props;
          }},on:{"end":function($event){return _vm.$emit('control-time')}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.hours)+" ч. "+_vm._s(props.minutes)+" мин.")]}}],null,false,98445967)})],1)]):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.hallsDialogDisplay),callback:function ($$v) {_vm.hallsDialogDisplay=$$v},expression:"hallsDialogDisplay"}},[_c('v-card',{attrs:{"height":"250"}},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" Редактиране на залите ")]),_c('v-card-text',[_c('vu-select',{attrs:{"options":_vm.halls,"label":"name","value":_vm.routemap.halls,"multiple":""},model:{value:(_vm.routemap.halls),callback:function ($$v) {_vm.$set(_vm.routemap, "halls", $$v)},expression:"routemap.halls"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.saveHalls()}}},[_vm._v(" Запиши ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }