<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Чекиране на оператор
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        Оператор
        <select v-model="operatorId" type="text" class="form-control">
          <option disabled :value="0">избери</option>
          <option
            v-for="operator in operators"
            :key="operator.id"
            :value="operator.id"
          >
            {{ operator.first_name }} {{ operator.last_name }}
          </option>
        </select>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click="handleSaveClicked">
          <span class="btn btn-primary btn-block">
            Запиши
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";
import { GET_DATA } from "@/store/formselect.module";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      operators: [],
      operatorId: 0,
      localErrorMessages: {}
    };
  },

  watch: {
    value() {
      this.operatorId = 0;
      this.localErrorMessages = {};
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  mounted() {
    this.fetchOperators();
  },

  methods: {
    fetchOperators() {
      let vm = this;
      let payload = {
        operators: {
          fields: ["id", "first_name", "last_name"],
          search: "1"
        }
      };

      vm.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.operators = data.data.operators;
          });
        })
        .catch(response => {
          vm.localErrorMessages = response.data.errors;
        });
    },

    handleSaveClicked() {
      if (this.operatorId < 1) {
        this.localErrorMessages = {
          0: ["Моля изберете оператор"]
        };

        return;
      }

      this.$emit("save-clicked", { operatorId: this.operatorId });
    },

    handleDialogInput(value) {
      this.operatorId = 0;
      this.localErrorMessages = {};

      this.$emit("input", value);
    }
  }
};
</script>
