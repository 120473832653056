<template>
  <div>
    <div v-if="routemap">
      <Header
        :user="user"
        :routemap="routemap"
        @refresh-click="fetchRoutemap"
        @refresh-route-map="fetchRoutemap"
        @control-time="fetchRoutemap"
      />

      <ErrorMessages v-if="errorMessages" :error-messages="errorMessages" />

      <HallCleaningMessage
        v-if="!routemap.hallsIsCleaned"
        :user="user"
        @clean-hall="cleanHall()"
      />

      <NextChargingMessage
        v-else-if="!routemap.isCharging"
        :routemap="routemap"
      />

      <RechargingMessage
        v-else-if="
          routemap.deniedControl && routemap.deniedControlSentToLabourer
        "
        :routemap="routemap"
      />

      <PreviousDayTimeToQualityControlMessage
        v-else-if="routemap.isStartOfTheDay"
        :routemap="routemap"
      />

      <QualityControlMessage
        v-else-if="
          routemap.status < 5 &&
            currentTimeIsBetweenWorkingTime &&
            !routemap.minutesToNextControl
        "
      />

      <BreakMessage :routemap="routemap" v-if="routemap.status === 4" />

      <div
        class="row p-4"
        :class="
          currentTimeIsBetweenWorkingTime &&
          !routemap.minutesToNextControl &&
          routemap.status < 5
            ? 'shouldDoControl'
            : ''
        "
      >
        <div class="col-lg-6 mb-3">
          <InfoSection :routemap="routemap" />
        </div>
        <div class="col-lg-6">
          <ControlsSection
            :routemap="routemap"
            :user="user"
            @pause-clicked="processPauseClicked"
            @start-clicked="processStartClicked"
            @control-clicked="qualityControlDialog = true"
            @raw-product-scrap-clicked="rawProductScrapDialog = true"
            @material-scrap-clicked="materialScrapDialog = true"
            @operator-check-clicked="operatorCheckDialog = true"
            @routemap-finish-clicked="finishDialog = true"
          />
        </div>
      </div>

      <CommentsSection
        :routemap="routemap"
        :user="user"
        @comment-added="routemap.comments.push($event)"
      />

      <CheckedOperatorsSection
        :routemap="routemap"
        :user="user"
        @checkout-operator="
          checkoutedOperator = $event;
          operatorCheckoutDialog = true;
        "
      />

      <PerformedOperationsSection
        :routemap="routemap"
        :user="user"
        @delete-operation="deleteOperation"
        @edit-operation="
          editedOperation = $event;
          editOperationDialog = true;
        "
      />

      <RawProductScrapsSection
        :routemap="routemap"
        :user="user"
        @delete-raw-product-scrap="deleteRawProductScrap"
      />

      <MaterialScrapsSection
        :routemap="routemap"
        :user="user"
        @delete-material-scrap="deleteMaterialScrap"
      />

      <PerformedQualityControlSection :routemap="routemap" />

      <StatisticsSection
        :routemap="routemap"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />

      <UsedRawMaterialsSection
        :routemap="routemap"
        :user="user"
        :calculate-returned-loader="calculateReturnedLoader"
        :save-cost-standard-loader="saveCostStandardLoader"
      />

      <PauseDialog
        v-model="pauseDialog"
        :routemap="routemap"
        :errorMessages="errorMessages"
        @pause-clicked="processPauseForm"
      />

      <StartDialog
        v-model="startDialog"
        :routemap="routemap"
        :errorMessages="errorMessages"
        @start-clicked="processStartForm"
      />

      <QualityControlDialog
        v-model="qualityControlDialog"
        :routemap="routemap"
        :errorMessages="errorMessages"
        @control-clicked="processControlForm"
      />

      <RawProductScrapDialog
        v-model="rawProductScrapDialog"
        :routemap="routemap"
        :errorMessages="errorMessages"
        @save-clicked="processRawProductScrapForm"
      />

      <MaterialScrapDialog
        v-model="materialScrapDialog"
        :routemap="routemap"
        :errorMessages="errorMessages"
        @save-clicked="processMaterialScrapForm"
      />

      <OperatorCheckDialog
        v-model="operatorCheckDialog"
        :routemap="routemap"
        :errorMessages="errorMessages"
        @save-clicked="processOperatorCheckForm"
      />

      <OperatorCheckoutDialog
        v-model="operatorCheckoutDialog"
        :routemap="routemap"
        :operator="checkoutedOperator"
        :errorMessages="errorMessages"
        @save-clicked="processOperatorCheckoutForm"
      />

      <OperationEditDialog
        v-model="editOperationDialog"
        :routemap="routemap"
        :editedOperation="editedOperation"
        :errorMessages="errorMessages"
        @save-clicked="processEditOperationForm"
      />

      <FinishDialog
        v-model="finishDialog"
        :routemap="routemap"
        @finish-clicked="processRoutemapFinishClicked"
      />

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingRoutemaps"
      >
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_ROUTEMAP_INFO,
  HALL_CONFIRM_CLEANING,
  START_ROUTEMAP,
  PAUSE_ROUTEMAP,
  RAW_PRODUCT_SCRAP,
  DELETE_RAW_PRODUCT_SCRAP,
  DELETE_MATERIAL_SCRAP,
  SCRAP_MATERIAL,
  FINISH_ROUTEMAP
} from "@/store/routemaps.module";
import {
  UPDATE_ROUTEMAPS_OPERATIONS,
  DELETE_ROUTEMAPS_OPERATIONS
} from "@/store/routemapsOperations.module";
import { OPERATOR_LOGIN, OPERATOR_LOGOUT } from "@/store/operators.module";
import Header from "@/views/pages/routemaps/components/actions/Header";
import { SAVE_ROUTEMAPS_MANAGER_CONTROL } from "@/store/routemapsControls.module";
import ErrorMessages from "@/views/pages/routemaps/components/actions/messages/ErrorMessages";
import HallCleaningMessage from "@/views/pages/routemaps/components/actions/messages/HallCleaningMessage";
import NextChargingMessage from "@/views/pages/routemaps/components/actions/messages/NextChargingMessage";
import RechargingMessage from "@/views/pages/routemaps/components/actions/messages/RechargingMessage";
import QualityControlMessage from "@/views/pages/routemaps/components/actions/messages/QualityControlMessage";
import BreakMessage from "@/views/pages/routemaps/components/actions/messages/BreakMessage";
import InfoSection from "@/views/pages/routemaps/components/actions/sections/InfoSection";
import ControlsSection from "@/views/pages/routemaps/components/actions/sections/ControlsSection";
import CommentsSection from "@/views/pages/routemaps/components/actions/sections/CommentsSection";
import CheckedOperatorsSection from "@/views/pages/routemaps/components/actions/sections/CheckedOperatorsSection";
import PerformedOperationsSection from "@/views/pages/routemaps/components/actions/sections/PerformedOperationsSection";
import RawProductScrapsSection from "@/views/pages/routemaps/components/actions/sections/RawProductScrapsSection";
import MaterialScrapsSection from "@/views/pages/routemaps/components/actions/sections/MaterialScrapsSection";
import PerformedQualityControlSection from "@/views/pages/routemaps/components/actions/sections/PerformedQualityControlSection";
import StatisticsSection from "@/views/pages/routemaps/components/actions/sections/StatisticsSection";
import UsedRawMaterialsSection from "@/views/pages/routemaps/components/actions/sections/UsedRawMaterialsSection";
import PreviousDayTimeToQualityControlMessage from "@/views/pages/routemaps/components/actions/messages/PreviousDayTimeToQualityControlMessage";
import PauseDialog from "@/views/pages/routemaps/components/actions/dialogs/PauseDialog";
import StartDialog from "@/views/pages/routemaps/components/actions/dialogs/StartDialog";
import QualityControlDialog from "@/views/pages/routemaps/components/actions/dialogs/QualityControlDialog";
import RawProductScrapDialog from "@/views/pages/routemaps/components/actions/dialogs/RawProductScrapDialog";
import MaterialScrapDialog from "@/views/pages/routemaps/components/actions/dialogs/MaterialScrapDialog";
import OperatorCheckDialog from "@/views/pages/routemaps/components/actions/dialogs/OperatorCheckDialog";
import OperatorCheckoutDialog from "@/views/pages/routemaps/components/actions/dialogs/OperatorCheckoutDialog";
import OperationEditDialog from "@/views/pages/routemaps/components/actions/dialogs/OperationEditDialog";
import FinishDialog from "@/views/pages/routemaps/components/actions/dialogs/FinishDialog";
import moment from "moment";
import router from "../../../router";

export default {
  components: {
    Header,
    ErrorMessages,
    HallCleaningMessage,
    NextChargingMessage,
    RechargingMessage,
    QualityControlMessage,
    BreakMessage,
    InfoSection,
    ControlsSection,
    CommentsSection,
    CheckedOperatorsSection,
    PerformedOperationsSection,
    RawProductScrapsSection,
    MaterialScrapsSection,
    PerformedQualityControlSection,
    StatisticsSection,
    UsedRawMaterialsSection,
    PreviousDayTimeToQualityControlMessage,
    PauseDialog,
    StartDialog,
    QualityControlDialog,
    RawProductScrapDialog,
    MaterialScrapDialog,
    OperatorCheckDialog,
    OperatorCheckoutDialog,
    OperationEditDialog,
    FinishDialog
  },

  data() {
    return {
      routemap: null,
      user: JSON.parse(window.localStorage.getItem("userData")),
      chartData: [
        ["Бройки", "Бройки"],
        ["оставащи", 0],
        ["направени", 0]
      ],
      chartOptions: {
        chart: {},
        slices: {
          0: {
            color: "red"
          },
          1: {
            color: "#3ebeaf"
          }
        },
        pieSliceText: "value"
      },
      pauseDialog: false,
      startDialog: false,
      qualityControlDialog: false,
      rawProductScrapDialog: false,
      materialScrapDialog: false,
      operatorCheckDialog: false,
      operatorCheckoutDialog: false,
      checkoutedOperator: {},
      editOperationDialog: false,
      calculateReturnedLoader: false,
      saveCostStandardLoader: false,
      finishDialog: false,
      editedOperation: {},
      errorMessages: [],
      timer: null,
      pauseTimeOut: null
    };
  },

  computed: {
    ...mapGetters(["isLoadingRoutemaps"]),
    currentTimeIsBetweenWorkingTime() {
      let startTime = moment(this.routemap.workingDateStartTime);
      let endTime = moment(this.routemap.workingDateEndTime);
      let currentTime = moment();

      return currentTime.isAfter(startTime) && currentTime.isBefore(endTime);
    }
  },

  mounted() {
    let vm = this;

    vm.fetchRoutemap();

    vm.timer = setInterval(() => {
      vm.fetchRoutemap();
    }, 30 * 1000);
  },

  methods: {
    deleteOperation(id) {
      let vm = this;

      vm.$store
        .dispatch(DELETE_ROUTEMAPS_OPERATIONS, id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchRoutemap();
        })
        .catch(response => {
          vm.errorMessages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["error"]({
              message: response.status
            });
          }
        });
    },

    deleteRawProductScrap(id) {
      let vm = this;

      vm.$store
        .dispatch(DELETE_RAW_PRODUCT_SCRAP, id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchRoutemap();
        })
        .catch(response => {
          vm.errorMessages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["error"]({
              message: response.status
            });
          }
        });
    },

    deleteMaterialScrap(id) {
      let vm = this;

      vm.$store
        .dispatch(DELETE_MATERIAL_SCRAP, id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchRoutemap();
        })
        .catch(response => {
          vm.errorMessages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["error"]({
              message: response.status
            });
          }
        });
    },

    fetchRoutemap(breakInterval = true) {
      let vm = this;

      vm.errorMessages = null;

      vm.$store
        .dispatch(GET_ROUTEMAP_INFO, vm.$route.params.id)
        .then(data => {
          vm.routemap = data.data;

          // Breaks fetch logic
          clearInterval(vm.pauseTimeOut);
          if (
            breakInterval &&
            vm.routemap.nextPause &&
            vm.routemap.nextPause.secondsToStartPause > 0
          ) {
            vm.pauseTimeOut = setTimeout(() => {
              vm.fetchRoutemap(false);
            }, (vm.routemap.nextPause.secondsToStartPause + 2) * 1000);
          }

          vm.chartData = [
            ["Бройки", "Бройки"],
            [
              "оставащи",
              vm.routemap.remainQuantity >= 0 ? vm.routemap.remainQuantity : 0
            ],
            ["направени", vm.routemap.readyQuantity]
          ];
        })
        .catch(response => {
          if (response.status === 404) {
            router.push({ name: "404" });
          }
        });
    },

    cleanHall() {
      let vm = this;

      vm.$store
        .dispatch(HALL_CONFIRM_CLEANING, {
          routemapId: vm.routemap.id
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchRoutemap();
        })
        .catch(response => {
          vm.errorMessages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["error"]({
              message: response.status
            });
            vm.$router.push({
              name: "routemapsList"
            });
          }
        });
    },

    processPauseClicked() {
      let vm = this;

      if (vm.routemap.status > 4) {
        return;
      }

      this.pauseDialog = true;
    },

    processPauseForm(form) {
      let vm = this;

      if (vm.routemap.status > 4) {
        return;
      }

      vm.errorMessages = [];

      vm.$store
        .dispatch(PAUSE_ROUTEMAP, {
          id: vm.routemap.id,
          form
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.pauseDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processStartClicked() {
      let vm = this;

      if (vm.routemap.status > 4) {
        return;
      }

      this.startDialog = true;
    },

    processStartForm(form) {
      let vm = this;

      if (vm.routemap.status > 4) {
        return;
      }

      vm.errorMessages = [];

      vm.$store
        .dispatch(START_ROUTEMAP, {
          id: vm.routemap.id,
          form
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.startDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processControlForm(form) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(SAVE_ROUTEMAPS_MANAGER_CONTROL, {
          payload: form
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.qualityControlDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processRawProductScrapForm(form) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(RAW_PRODUCT_SCRAP, {
          id: vm.routemap.id,
          form
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.rawProductScrapDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processMaterialScrapForm(form) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(SCRAP_MATERIAL, {
          id: vm.routemap.id,
          form
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.materialScrapDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processOperatorCheckForm(form) {
      let vm = this;

      vm.errorMessages = [];

      form.routeMapId = vm.routemap.id;

      vm.$store
        .dispatch(OPERATOR_LOGIN, form)
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.operatorCheckDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processOperatorCheckoutForm(form) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(OPERATOR_LOGOUT, form)
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.operatorCheckoutDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processEditOperationForm(form) {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(UPDATE_ROUTEMAPS_OPERATIONS, { id: form.id, form })
        .then(() => {
          vm.$notification["success"]({
            message: "Успешна операция"
          });

          vm.editOperationDialog = false;

          vm.fetchRoutemap();
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    },

    processRoutemapFinishClicked() {
      let vm = this;

      vm.errorMessages = [];

      vm.$store
        .dispatch(FINISH_ROUTEMAP, {
          id: vm.routemap.id
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({
            name: "routemapsListDone"
          });
        })
        .catch(response => {
          if (response.data.errors) {
            vm.errorMessages = response.data.errors;

            vm.$scrollTo("html", 420, {
              offset: -300
            });
          } else {
            vm.$notification["error"]({
              message: "Нещо се обърка"
            });
          }
        });
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
