<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ routemap.name }} {{ routemap.quantity }} бр.
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        Коментар:
        <textarea v-model="comment" class="form-control"></textarea>
        <br />
        <v-col class="12">
          <input
            v-model="needCleaning"
            type="checkbox"
            class="form-control dl-inline"
          />
          Отбележете тук, ако има нужда от почистване!
        </v-col>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text>
          <span class="btn btn-danger btn-block" @click="handlePauseClicked">
            ПАУЗА
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      comment: "",
      needCleaning: false,
      localErrorMessages: {}
    };
  },

  watch: {
    value() {
      this.comment = "";
      this.needCleaning = false;
      this.localErrorMessages = {};
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  methods: {
    handlePauseClicked() {
      if (!this.comment) {
        this.localErrorMessages = {
          0: ["Полето коментар е задължително"]
        };

        return;
      }

      this.$emit("pause-clicked", {
        comment: this.comment,
        needCleaning: this.needCleaning
      });
    },

    handleDialogInput(value) {
      this.comment = "";
      this.needCleaning = false;
      this.localErrorMessages = {};

      this.$emit("input", value);
    }
  }
};
</script>
