<template>
  <div class="alert alert-info">
    <h3 class="mb-0">Залата е отбелязана за почистване.</h3>
    <div class="d-flex align-items-center justify-content-between">
      <h4 v-if="['Admin', 'Manager'].includes(user.role)" class="mb-0">
        За да продължите работа, моля потвърдете, че почистването е извършено!
      </h4>
      <button
        v-if="['Admin', 'Manager'].includes(user.role)"
        @click="cleanHall()"
        class="btn btn-block btn-danger"
      >
        Потвърди
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  },

  methods: {
    cleanHall() {
      this.$emit("clean-hall");
    }
  }
};
</script>

<style scoped>
.alert {
  display: block;
}

.btn {
  max-width: 300px;
}
</style>
