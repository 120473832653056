var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pl-0"},[_c('h4',[_vm._v(" "+_vm._s(_vm.routemap.nextPause.minutes)+" минути почивка от "+_vm._s(_vm.routemap.nextPause.from)+" до "+_vm._s(_vm.routemap.nextPause.to)+" "),_c('br')])]),_c('div',{staticClass:"d-flex"},[_c('h4',[_vm._v("Оставащо време:")]),_c('h4',{staticClass:"ml-3"},[_c('countdown',{attrs:{"time":_vm.routemap.nextPause.secondsToFinishPause * 1000,"transform":props => {
              Object.entries(props).forEach(([key, value]) => {
                // Adds leading zero
                const digits = value < 10 ? `0${value}` : value;
                props[key] = `${digits}`;
              });
              return props;
            }},on:{"end":function($event){return _vm.$emit('break-finished')}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.minutes)+" мин. "+_vm._s(props.seconds)+" с. ")]}}])})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }