<template>
  <div class="row">
    <span
      v-if="routemap.status !== 2 && ['Admin', 'Manager'].includes(user.role)"
      class="col-6 btn btn-lg mr btn-height btn-primary btn-block"
      @click="$emit('start-clicked')"
    >
      СТАРТ
    </span>
    <span
      v-else-if="['Admin', 'Manager'].includes(user.role)"
      class="col-6 btn btn-lg mr btn-height btn-primary btn-block"
      @click="handlePauseClicked"
    >
      ПАУЗА
    </span>
    <span
      v-if="['Admin', 'Quality Manager'].includes(user.role)"
      class="col-6 btn btn-lg btn-success btn-height"
      :class="
        !['Admin', 'Quality Manager'].includes(user.role) ? 'disabled' : ''
      "
      @click="handleControlClicked"
      >Извърши контрол на качеството</span
    >

    <span
      v-if="['Admin', 'Manager'].includes(user.role)"
      class="col-6 btn btn-lg btn-success btn-height mr"
      @click="handleRawProductScrapClicked"
      >Брак на суровина</span
    >

    <span
      v-if="['Admin', 'Manager'].includes(user.role)"
      class="col-6 btn btn-lg btn-success btn-height"
      @click="handleMaterialScrapClicked"
      >Брак на материали</span
    >
    <span
      v-if="['Admin', 'Manager'].includes(user.role)"
      class="col-6 btn btn-lg btn-primary btn-height mr"
      @click="handleOperatorCheckClicked"
      >Чекирай оператор</span
    >
    <span
      v-if="['Admin', 'Qualified', 'Manager'].includes(user.role)"
      class="col-6 btn btn-lg btn-danger btn-height"
      @click="handleRoutemapFinishClicked"
      >Приключи маршрутна карта</span
    >
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    routemap: {
      required: true,
      type: Object
    },

    user: {
      required: true,
      type: Object
    }
  },

  computed: {
    activeOperators() {
      return _.filter(this.routemap.operators, { active: true });
    }
  },

  methods: {
    handlePauseClicked() {
      this.$emit("pause-clicked");
    },

    handleControlClicked() {
      if (
        ["Admin", "Qualified", "Quality Manager", "Hall"].includes(
          this.user.role
        )
      ) {
        this.$emit("control-clicked");
      }
    },

    handleRawProductScrapClicked() {
      if (
        this.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(this.user.role)
      ) {
        return;
      }

      this.$emit("raw-product-scrap-clicked");
    },

    handleMaterialScrapClicked() {
      if (
        this.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(this.user.role)
      ) {
        return;
      }

      this.$emit("material-scrap-clicked");
    },

    handleOperatorCheckClicked() {
      if (
        this.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(this.user.role)
      ) {
        return;
      }

      this.$emit("operator-check-clicked");
    },

    handleRoutemapFinishClicked() {
      if (this.routemap.status > 4) {
        return;
      }

      this.$emit("routemap-finish-clicked");
    }
  }
};
</script>
