import { render, staticRenderFns } from "./PerformedQualityControlSection.vue?vue&type=template&id=3720b1ca&scoped=true&"
import script from "./PerformedQualityControlSection.vue?vue&type=script&lang=js&"
export * from "./PerformedQualityControlSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3720b1ca",
  null
  
)

export default component.exports