<template>
  <div class="alert alert-info">
    От последния работен ден са останали
    {{ routemap.minutesToNextControl }} минути до контрол на качество.
    <br />
    Моля, чекирайте се като оператор, за да стартирате процеса на работа!
  </div>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  }
};
</script>

<style scoped></style>
