<template>
  <div class="mb-5">
    <h4 class="mt-3">Извършени операции</h4>

    <v-row class="t-head">
      <v-col>Дата</v-col>
      <v-col>Операция</v-col>
      <v-col>Бройка</v-col>
      <v-col>Оператор</v-col>
      <v-col>Корекция</v-col>
      <v-col>Изтриване</v-col>
    </v-row>
    <template v-for="operation in routemap.operations">
      <v-row class="row border-bottom" v-bind:key="'oo' + operation.id">
        <v-col class="t-row">{{ operation.date }}</v-col>
        <v-col class="t-row">{{ operation.operation }}</v-col>
        <v-col class="t-row">{{ operation.quantity }}</v-col>
        <v-col class="t-row"
          >{{ operation.operator }}
          <i v-if="operation.edited_by > 0">коригирана</i>
        </v-col>
        <v-col class="t-row">
          <span
            v-if="['Admin', 'Qualified', 'Manager'].includes(user.role)"
            class="btn btn-block btn-danger"
            @click="handleEditOperationClicked(operation)"
            >Промени</span
          >
        </v-col>
        <v-col class="t-row">
          <span
            v-if="['Admin', 'Qualified', 'Manager'].includes(user.role)"
            class="btn btn-block btn-danger"
            @click="handleDeleteOperationClicked(operation.id)"
            >Изтриване</span
          >
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "PerformedOperationsSection",
  props: {
    routemap: {
      required: true,
    },
    user: {
      required: true,
      type: Object,
    },
  },

  methods: {
    handleEditOperationClicked(operation) {
      if (
        this.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(this.user.role)
      ) {
        return;
      }

      this.$emit("edit-operation", operation);
    },

    handleDeleteOperationClicked(operationId) {
      if (
        this.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(this.user.role)
      ) {
        return;
      }

      this.$emit("delete-operation", operationId);
    },
  },
};
</script>

<style scoped></style>
