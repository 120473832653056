<template>
  <div class="mb-5">
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <h4>
      Коментари
    </h4>
    <v-row dense>
      <v-col cols="12">
        <v-card
          color="#e8e8e8"
          class="mb-2"
          v-for="comment in routemap.comments"
          :key="comment.id"
        >
          <div
            class="d-flex justify-content-between align-items-center flex-wrap-reverse"
          >
            <v-card-subtitle class="comment">{{
              comment.comment
            }}</v-card-subtitle>
            <v-card-title class="text-h5 comment-heading">
              {{ comment.userName }} {{ comment.createdAt }}
            </v-card-title>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex" v-if="['Admin', 'Manager'].includes(user.role)">
      <input class="form-control" v-model="comment" />
      <button class="btn btn-primary btn-comment ml-2" @click="addComment">
        Добави коментар
      </button>
    </div>
  </div>
</template>

<script>
import { ROUTE_MAP_ADD_COMMENT } from "@/store/routemaps.module";

export default {
  props: {
    routemap: {
      required: true,
      type: Object
    },
    user: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      error_messages: [],
      comment: null
    };
  },
  methods: {
    addComment: function() {
      let vm = this;

      vm.error_messages = [];

      vm.$store
        .dispatch(ROUTE_MAP_ADD_COMMENT, {
          payload: { comment: vm.comment, routeMapId: vm.routemap.id }
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$nextTick(function() {
            vm.comment = null;
            return vm.$emit("comment-added", data.data);
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
        });
    }
  }
};
</script>

<style scoped>
.comment-heading {
  font-size: 15px;
  padding: 8px;
}

.comment {
  font-size: 14px;
  padding: 8px;
}

.btn-comment {
  width: 190px;
}
</style>
