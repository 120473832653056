<template>
  <v-dialog :value="value" @input="handleDialogInput" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ routemap.name }}
      </v-card-title>

      <v-card-text>
        <ErrorMessages :errorMessages="localErrorMessages" />

        <template>
          Брой на бракувани суровини
          <input
            type="number"
            min="1"
            v-model="quantity"
            class="form-control"
          />
        </template>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click="handleSaveClicked()">
          <span class="btn btn-primary btn-block">
            Запиши
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";

export default {
  components: {
    ErrorMessages
  },

  props: {
    routemap: {
      required: true,
      type: Object
    },

    value: {
      required: true,
      type: Boolean
    },

    errorMessages: {
      default: () => {}
    }
  },

  data() {
    return {
      quantity: 1,
      localErrorMessages: {}
    };
  },

  watch: {
    value() {
      this.quantity = 1;
      this.localErrorMessages = {};
    },

    errorMessages() {
      this.localErrorMessages = this.errorMessages;
    }
  },

  methods: {
    handleSaveClicked() {
      if (this.quantity < 1) {
        this.localErrorMessages = {
          0: ["Броя на бракувани суровини трябва да бъде минимум 1"]
        };

        return;
      }

      this.$emit("save-clicked", {
        quantity: this.quantity
      });
    },

    handleDialogInput(value) {
      this.quantity = 1;
      this.localErrorMessages = {};

      this.$emit("input", value);
    }
  }
};
</script>
