<template>
  <div>
    <div class="row t-head">
      <div class="col">Описание на изходни суровини / опаковъчни материали</div>
      <div class="col text-center">Партиден &numero;</div>
      <div class="col text-center">Срок на годност</div>
      <div class="col text-center">Количество<br />бр.</div>
      <div class="col text-center">Вложени бр.</div>
      <div class="col text-center">Мостра</div>
      <div class="col text-center">Брак<br />бр.</div>
      <div class="col text-center">Върнати<br />бр.</div>
    </div>

    <template v-for="(cost, i) in costStandarts">
      <div class="row border-bottom" v-bind:key="'cs' + i">
        <div class="col text-strong">{{ cost.type }} {{ cost.name }}</div>
        <div class="col text-center">
          <span v-for="(b, kk) in cost.batch" v-bind:key="'csk' + kk">
            {{ b }}
          </span>
        </div>
        <div class="col text-center">{{ cost.expirationDate }}</div>
        <div class="col text-center">{{ cost.allQuantity }}</div>
        <template v-if="cost.primaryQIB > 0">
          <div class="col text-center">
            {{ cost.inputtedQuantity }}
          </div>
        </template>
        <template v-else>
          <div class="col text-center">X</div>
        </template>
        <div class="col text-center">{{ cost.allSample }}</div>
        <div class="col text-center">{{ cost.allScrap }}</div>
        <div class="col text-center">
          {{ cost.allReturned }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    calculateReturnedLoader: {
      required: true,
      type: Boolean,
    },

    saveCostStandardLoader: {
      required: true,
      type: Boolean,
    },

    routemap: {
      required: true,
      type: Object,
    },

    user: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      costStandarts: { ...this.routemap.costStandards },
    };
  },

  watch: {
    routemap() {
      this.costStandarts = { ...this.routemap.costStandards };
    },
  },
};
</script>

<style scoped>
.text-white {
  color: white;
}
</style>
