<template>
  <div>
    <button class="btn btn-primary float-right" @click="goBack()">
      <i class="fa fa-arrow-left fa-fw"></i> назад
    </button>

    <button
      v-if="['Admin', 'Qualified', 'Manager', 'Hall'].includes(user.role)"
      class="btn btn-danger float-right mr-2 ml-2"
      @click="openLog(`/logs/route-map/${routemap.id}`)"
    >
      <i class="fas fa-exclamation fa-fw"></i> лог
    </button>

    <button
      v-if="['Admin', 'Qualified', 'Manager', 'Hall'].includes(user.role)"
      class="btn btn-warning float-right mr-2 ml-2"
      @click="openLog(`/logs/route-map/${routemap.id}/printout`)"
    >
      <i class="fas fa-exclamation fa-fw"></i> разпечатка
    </button>

    <button
      class="btn btn-success float-right mr-2 ml-2"
      @click="$emit('refresh-click')"
    >
      <i class="fas fa-sync fa-fw"></i>
    </button>

    <h1>
      {{ routemap.name }} - {{ routemap.quantity }} бр. <br />
      {{ routemap.hallNames }}
      <v-tooltip
        top
        v-if="
          ['Admin', 'Manager'].includes(user.role) ||
            (user.role == 'Quality Manager' && routemap.status > 1)
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            fab
            dark
            x-small
            color="#000000"
            v-bind="attrs"
            v-on="on"
            @click="displayHallsDialog"
          >
            <v-icon> fas fa-pen </v-icon>
          </v-btn>
        </template>
        <span>Редактирай залите</span>
      </v-tooltip>
    </h1>

    <h1 class="text-danger">
      {{ routemap.statusName }}
    </h1>

    <h4
      v-if="routemap.status == 2 && routemap.secondsToNextControl > 0"
      class="mt-3"
      style="color: red"
    >
      Контрол на качеството след
      <strong>
        <countdown
          @end="$emit('control-time')"
          :auto-start="
            !routeMapIsPaused && !routeMapIsNew && !routeMapIsInBreak
          "
          :time="routemap.secondsToNextControl * 1000"
          :transform="
            props => {
              Object.entries(props).forEach(([key, value]) => {
                // Adds leading zero
                const digits = value < 10 ? `0${value}` : value;
                props[key] = `${digits}`;
              });
              return props;
            }
          "
        >
          <template slot-scope="props"
            >{{ props.hours }} ч. {{ props.minutes }} мин.</template
          >
        </countdown>
      </strong>
    </h4>
    <v-dialog v-model="hallsDialogDisplay" width="500">
      <v-card height="250">
        <v-card-title class="headline grey lighten-2">
          Редактиране на залите
        </v-card-title>

        <v-card-text>
          <vu-select
            :options="halls"
            label="name"
            v-model="routemap.halls"
            :value="routemap.halls"
            multiple
          ></vu-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="saveHalls()"> Запиши </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_ALL_HALLS } from "@/store/halls.module";
import { SAVE_HALLS } from "@/store/routemaps.module";

export default {
  props: {
    user: {
      required: true,
      type: Object
    },
    routemap: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      halls: [],
      hallsDialogDisplay: false,
      hallsSchema: {
        fields: []
      }
    };
  },
  computed: {
    routeMapIsPaused() {
      return this.routemap.status == 3;
    },
    routeMapIsNew() {
      return this.routemap.status == 1;
    },
    routeMapIsInBreak() {
      return this.routemap.status == 4;
    }
  },
  methods: {
    saveHalls: function() {
      let vm = this;

      vm.$store
        .dispatch(SAVE_HALLS, {
          id: vm.routemap.id,
          payload: { halls: vm.routemap.halls }
        })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$nextTick(function() {
            this.$emit("refresh-route-map");

            vm.hallsDialogDisplay = false;
          });
        });
    },
    displayHallsDialog: function() {
      let vm = this;

      if (
        vm.routemap.status > 5 &&
        !["Admin", "Qualified"].includes(vm.user.role)
      ) {
        return;
      }

      if (vm.halls.length === 0) {
        vm.$store.dispatch(GET_ALL_HALLS).then(data => {
          vm.$nextTick(function() {
            vm.halls = data.data;
            vm.hallsDialogDisplay = true;
          });
        });
      } else {
        vm.hallsDialogDisplay = true;
      }
    },
    goBack() {
      const route =
        this.routemap.status === 1 ? "routemapsListNew" : "routemapsList";

      this.$router.push({ name: route });
    },

    openLog: function(url) {
      window.open(url, "_blank", "width=600,height=800");
    }
  }
};
</script>
