<template>
  <div class="row no-gutters">
    <template v-if="routemap.firstControlQA">
      <div class="col-6 mb-2 text-strong">
        Контрол на качеството:
      </div>
      <div class="col-6 mb-2">
        {{ routemap.firstControlQA }}
      </div>
    </template>
    <template v-if="routemap.firstControlTime">
      <div class="col-6 mb-2 text-strong">
        Първи контрол за деня:
      </div>
      <div class="col-6 mb-2">
        {{ routemap.firstControlTime }}
      </div>
    </template>

    <template v-if="routemap.lastControlTime">
      <div class="col-6 mb-2 text-strong">
        Последно извършен контрол:
      </div>
      <div class="col-6 mb-2">
        {{ routemap.lastControlTime }}
      </div>
    </template>

    <template>
      <div class="col-6 mb-2 text-strong">
        Извършени контроли днес:
      </div>
      <div class="col-6 mb-2">
        {{ routemap.controlsToday ? routemap.controlsToday : 0 }}
      </div>
    </template>

    <template>
      <div class="col-6 mb-2 text-strong">Следваща почивка:</div>
      <div class="col-6 mb-2">
        <span v-if="routemap.nextPause"
          >{{ routemap.nextPause.minutes }} минути от
          {{ routemap.nextPause.from }} до {{ routemap.nextPause.to }}</span
        >
        <span v-else>Няма</span>
      </div>
    </template>

    <template>
      <div class="col-6 mb-2 text-strong">Извършени паузи днес:</div>
      <div class="col-6 mb-2">
        {{ routemap.pausesToday ? routemap.pausesToday : 0 }}
      </div>
    </template>

    <template
      v-if="routemap.performedChargingBy && routemap.performedChargingDate"
    >
      <div class="col-6 mb-2 text-strong">Извършил зареждане:</div>
      <div class="col-6 mb-2">
        <span
          >{{ routemap.performedChargingBy }} на
          {{ routemap.performedChargingDate }}</span
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    routemap: {
      required: true,
      type: Object
    }
  }
};
</script>
